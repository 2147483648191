<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      classifications: [],
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  if (this.currNode.type == '1001') {
                    let { data } = this.formOptions;
                    data["parentId"] = this.currNode.id;
                    this.getClassification(this.formOptions.data.clientType);
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "title",
            title: "通知标题",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入通知标题"
              }
            }
          },
          {
            field: "noticeType",
            title: "通知类型",
            minWidth: 160,
            editRender: {
              name: "$select",
              props: {
                placeholder: "请选择通知类型"
              }
            }
          },
          {
            field: "clientType",
            title: "通知对象",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "clientType",
              props: {
                placeholder: "请选择通知对象"
              }
            }
          },
          {
            field: "createTime",
            title: "发布时间",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                type: "dateTime",
                placeholder: "请选择起始时间",
              }
            },
          },
          // {
          //   field: "startTime",
          //   title: "起始时间",
          //   minWidth: 120,
          //   editRender: {
          //     name: "$input",
          //     props: {
          //       type: "date",
          //       placeholder: "请选择起始时间",
          //     }
          //   },
          // },
          // {
          //   field: "endTime",
          //   title: "结束时间",
          //   minWidth: 120,
          //   editRender: {
          //     name: "$input",
          //     props: {
          //       type: "date",
          //       placeholder: "请选择结束时间",
          //     }
          //   },
          // },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row)
                }
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 1000,
        titleWidth: 100,
        items: [{
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  this.currNode = node;
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                    data["noticeType"] = "";
                    this.getClassification(data.clientType);
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "title",
            title: "通知标题",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入通知标题"
              }
            }
          },
          {
            field: "clientType",
            title: "客户端",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "clientType",
              props: {
                placeholder: "请选择客户端",
              },
              events: {
                change: ({ data, property }) => {
                  data["noticeType"] = "";
                  this.getClassification(data[property]);
                }
              }
            },
          },
          {
            field: "noticeType",
            title: "通知类型",
            span: 12,
            itemRender: {
              name: "$select",
              props: {
                placeholder: "请选择通知类型",
              },
            },
          },
          // {
          //   field: "startTime",
          //   title: "起始时间",
          //   span: 12,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       type: "date",
          //       placeholder: "请选择起始时间",
          //     }
          //   },
          // },
          // {
          //   field: "endTime",
          //   title: "结束时间",
          //   span: 12,
          //   itemRender: {
          //     name: "$input",
          //     props: {
          //       type: "date",
          //       placeholder: "请选择结束时间",
          //     }
          //   },
          // },
          // {
          //   field: "pushTag",
          //   title: "是否推送",
          //   span: 12,
          //   itemRender: {
          //     name: "$select",
          //     optionTypeCode: "isIt",
          //     props: {
          //       placeholder: "请选择是否推送"
          //     }
          //   }
          // },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            field: "context",
            title: "通知内容",
            span: 24,
            itemRender: {
              name: "$VxeEdit",
              props: {
                maxlength: 24,
                placeholder: "请输入通知内容"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          title: [{ required: true, message: "请输入通知标题" }],
          pushTag: [{ required: true, message: "请选择是否推送" }],
          enabled: [{ required: true, message: "请选择状态" }],
          noticeType: [{ required: true, message: "请选择通知类型" }],
          clientType: [{ required: true, message: "请选择通知对象" }],
          context: [{ required: true, message: "请输入通知内容" }],
        },
        // 新增功能字段初始化
        data: {
          pushTag: "0",
          enabled: "1",
          noticeType: "",
          clientType: "1",
          title: "",
          clickUserId: "",
          parentId: "",
          context: "",
          startTime: "",
          endTime: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "title",
            title: "通知标题",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入通知标题"
              }
            }
          },
          {
            field: "clientType",
            title: "客户端",
            itemRender: {
              name: "$select",
              optionTypeCode: "clientType",
              props: {
                placeholder: "请选择客户端",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            folding: true,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            align: "center",
            collapseNode: true,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          title: "",
          clientType: "",
          enabled: ""
        },
      },
    };
  },
  methods: {
    ...mapActions(['findByEventService']),
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.getClassification();
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    },

    // 获取通知分类
    getClassification(clientType) {
      this.findByEventService({
          service: "/wisdom/category",
          params: {
            parentId: this.currNode.id,
            clientType: clientType,
            enabled: "1"
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.classifications = data;
            this.gridOptions.columns = [...this.$Tools.buildDataToGrid(this.gridOptions.columns, data, "noticeType", { value: "id", label: "content" })];
            this.formOptions.items = [...this.$Tools.buildDataToGrid(this.formOptions.items, data, "noticeType", { value: "id", label: "content" })];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
